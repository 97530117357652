import {
  useLoaderData,
  json,
  useActionData,
  Form,
  redirect,
  useSubmit,
  Link,
  useNavigation,
} from '@remix-run/react';
import {
  type ActionFunction,
  type LoaderFunctionArgs,
  type MetaFunction,
} from '@remix-run/node';
import { useEffect, useState } from 'react';
import { SEOHandle } from '@nasa-gcn/remix-seo';

import Footer from '#app/components/footer';
import NewsletterBar from '#app/components/newsletter-bar';
import TopBar from '#app/components/top-bar';
import { Container, ContainerContent } from '#app/components/layouts';
import { Input } from '#app/components/ui/input';
import { Label } from '#app/components/ui/label';
import { cn } from '#app/utils/misc';
import { getNavData } from '#app/models/nav.server';
import { handleLogin, getUserToken } from '#app/utils/auth.server';
import { useGoogleRecaptcha } from '#app/hooks/useGoogleRecaptcha';
import { getEnv } from '#app/utils/env.server';

export async function loader({ request }: LoaderFunctionArgs) {
  const [navResponse] = await Promise.all([getNavData()]);
  const {
    data: { header, footer, general },
  } = navResponse;

  const envVars = getEnv();

  const userToken = await getUserToken(request);
  if (userToken) return redirect('/');

  const recaptchaSiteKey = process.env.RECAPTCHA_SITEKEY;

  return json({
    header,
    footer,
    general,
    recaptchaSiteKey,
    envVars,
  });
}

export const meta: MetaFunction = () => {
  return [{ title: `Login | VPT, Inc.` }];
};

export let action: ActionFunction = async ({ request }) => {
  const formData = await request.formData();
  return handleLogin(formData, request);
};

export const handle: SEOHandle = {
  getSitemapEntries: () => null,
};

export default function LoginPage() {
  const { header, footer, general, recaptchaSiteKey, envVars } =
    useLoaderData<typeof loader>();
  const data = useActionData<FormActionData>();

  const [recaptchaErr, setRecaptchaErr] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if (!data?.error) return;
    setErrorMsg(data.error);
  }, [data]);

  const submit = useSubmit();
  const navigation = useNavigation();

  const { ready, execute } = useGoogleRecaptcha({
    siteKey: recaptchaSiteKey,
    action: 'login',
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    recaptchaErr ?? setRecaptchaErr(false);
    setErrorMsg('');

    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);

    if (ready && recaptchaSiteKey) {
      try {
        const token = await execute();
        if (token) {
          formData.append('recaptchaToken', token);
          submit(formData, { method: 'post' });
        } else {
          setRecaptchaErr(true);
          console.error('Error generating token');
        }
      } catch (err) {
        setRecaptchaErr(true);
        console.error('Recaptcha failed', err);
      }
    } else {
      submit(formData, { method: 'post' });
    }
  };

  return (
    <div className="grid min-h-full grid-cols-[100%]">
      <TopBar data={header} notification={general} envVars={envVars} />
      <main
        className={cn(
          'relative bg-white pb-44',
          general.enableNotificationBar ? 'top-[116px]' : 'top-[66px]',
        )}
      >
        <div className="min-h-[500px]">
          <Container className="pt-11">
            <ContainerContent>
              <div className="px-[15px] md:px-[30px] xl:px-[15px] pt-5 lg:pt-0">
                <h1 className="mb-6 text-3xl font-medium">Login Customer</h1>
                <Form
                  method="post"
                  className="mb-3 w-[500px]"
                  style={{ width: '500px' }}
                  onSubmit={handleSubmit}
                >
                  <div className="mb-4">
                    <Label>Email</Label>
                    <Input
                      className="text-gray-100 mt-2 h-[40px] pr-8"
                      type="email"
                      name="email"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <Label>Password</Label>
                    <Input
                      className="text-gray-100 mt-2 h-[40px] pr-8"
                      type="password"
                      name="password"
                      required
                    />
                  </div>
                  <button
                    className="mt-2 h-11 w-[150px] bg-primary text-sm font-medium text-white hover:bg-primary-510 disabled:pointer-events-none disabled:opacity-50"
                    type="submit"
                    disabled={
                      !ready ||
                      navigation.state === 'submitting' ||
                      navigation.state === 'loading'
                    }
                  >
                    Log In
                  </button>
                  <div className="mt-5 flex items-center">
                    <div className="mr-8 flex items-center">
                      <Label className="flex items-center text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                        <Input
                          className="text-gray-100 mr-2 h-[20px] w-auto"
                          type="checkbox"
                          name="remember"
                        />
                        <span>Remember Me</span>
                      </Label>
                    </div>
                    <Link
                      to={`/forgot-password`}
                      className="text-sm text-primary hover:underline"
                      prefetch="intent"
                    >
                      Forgot Password?
                    </Link>
                  </div>
                </Form>
                {errorMsg && (
                  <div className="mt-5 text-sm text-error">{errorMsg}</div>
                )}
                {!data && recaptchaErr && (
                  <div className="mt-5 text-sm text-error">
                    {'Network Error. Try again later.'}
                  </div>
                )}
              </div>
            </ContainerContent>
          </Container>
        </div>
      </main>
      <NewsletterBar />
      <Footer data={footer} />
    </div>
  );
}
